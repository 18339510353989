import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Topbar(props: any) {
    const navigate = useNavigate();

    return (
        <Row className='mx-0 px-0 topmain'>
            <Col
                md={12}
                className='topwrap px-0'>
                <Row className='mx-0'>
                    <Col
                        md={8}
                        className='topsearch px-0'></Col>
                    <Col
                        md={4}
                        className='px-0'>
                        <button
                            onClick={() => navigate(-1)}
                            className='top-back-btn'>
                            <img
                                src='public/assets/images/back.png'
                                className='img-fluid'
                                alt='Back'
                            />
                        </button>
                        <button>
                            <img
                                src='public/assets/images/topicn.png'
                                className='img-fluid'
                                alt='Setting'
                            />
                        </button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
export default Topbar;
