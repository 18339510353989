import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import React, { Suspense } from 'react';

import { AuthenticationManager } from '@frontend/authentication';
import Topbar from './Pages/topbar';
import Spinner from './Spinner';
import PrivateLoginRoute from './Utils/PrivateLoginRoute';
import PrivateRoute from './Utils/PrivateRoute';

const LoginModule = React.lazy(() => import('./authentication/login/login.component'));
const TransactionModule = React.lazy(() => import('./Pages/Transaction/Transaction'));
const StatusModule = React.lazy(() => import('./Pages/Status/Status'));
const ScanModule = React.lazy(() => import('./Pages/Scan/Scan'));
const ProfileModule = React.lazy(() => import('./Pages/Profile/Profile'));
const DropOffModule = React.lazy(() => import('./Pages/DropOff/DropOff'));
const ContactModule = React.lazy(() => import('./Pages/Contact/Contact'));

function App() {
    return (
        <div className='g-sidenav-show g-sidenav-pinned'>
            <div className='min-height-300 bg-primary position-absolute w-100' />
            <main className='d-flex main-content position-relative border-radius-lg'>
                <div className='container-fluid ps-4'>
                    <Suspense fallback={<Spinner />}>
                        <Router>
                            {AuthenticationManager.getInstance().loggedIn && <Topbar />}
                            <Routes>
                                <Route element={<PrivateLoginRoute />}>
                                    <Route
                                        path='/login/'
                                        element={<LoginModule />}
                                    />

                                    <Route
                                        path='/logout'
                                        element={
                                            <Navigate
                                                replace
                                                to='/login'
                                            />
                                        }
                                    />
                                    <Route
                                        path='/'
                                        element={
                                            <Navigate
                                                replace
                                                to='/login'
                                            />
                                        }
                                    />
                                    <Route
                                        path='/*'
                                        element={
                                            <Navigate
                                                replace
                                                to={'/login'}
                                            />
                                        }
                                    />
                                </Route>

                                <Route element={<PrivateRoute />}>
                                    <Route
                                        path='/profile/'
                                        element={<ProfileModule />}
                                    />
                                    <Route
                                        path='/scan/'
                                        element={<ScanModule />}
                                    />
                                    <Route
                                        path='/contact/'
                                        element={<ContactModule />}
                                    />
                                    <Route
                                        path='/transaction/'
                                        element={<TransactionModule />}
                                    />
                                    <Route
                                        path='/dropoff/'
                                        element={<DropOffModule />}
                                    />
                                    <Route
                                        path='/status/'
                                        element={<StatusModule />}
                                    />
                                </Route>
                            </Routes>
                        </Router>
                    </Suspense>
                </div>
            </main>
        </div>
    );
}

export default App;
